<template>
  <div>
    <!-- <pre>form.metrics: {{ form.metrics }}</pre> -->
    <!-- <pre>form.type: {{ form.type }}</pre> -->
    <!-- <pre>form.category: {{ form.category }}</pre> -->
    <!-- <pre>form.subcategory: {{ form.subcategory }}</pre> -->
    <!-- <pre>category: {{ category }}</pre> -->
    <!-- <pre>2 form.category: {{ form.category }}</pre> -->
    <!-- <pre>extendedCategories: {{ extendedCategories }}</pre> -->

    <div v-if="loading" class="text-center">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>

    <template v-else>
      <b-alert
        :variant="msg.type"
        dismissible
        class="mt-3"
        v-model="msg.has"
        :show="msg.text"
        >{{ msg.text }}</b-alert
      >

      <form @submit.prevent="handleSubmit" novalidate>
        <!-- name -->
        <div class="form-group mb-2">
          <label for="login" class="required">{{ $t("form.name") }}</label>
          <input
            class="form-control"
            v-model="form.name"
            id="login"
            :placeholder="$t('form.name-placeholder')"
            :readonly="type === 'view'"
            :class="[
              {
                'is-invalid': submitted && $v.form.name.$error,
              },
              type === 'view' ? 'form-control-plaintext' : 'form-control',
            ]"
          />
          <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
            <span v-if="!$v.form.name.required">{{ $t("form.name-req") }}</span>
          </div>
        </div>

        <!-- category: category/subcategory || custom  -->
        <template v-if="!custom">
          <!-- category -->
          <div class="form-group mb-2">
            <label for="category" class="required">{{
              $t("form.category")
            }}</label>
            <div v-if="type === 'view'" class="input-group input-group-merge">
              <input
                v-if="form.category && form.category.name"
                :value="form.category.name"
                readonly
                class="form-control form-control-plaintext"
              />
              <input
                v-else
                value=""
                readonly
                class="form-control form-control-plaintext"
              />
            </div>
            <div v-else class="input-group input-group-merge">
              <multiselect
                v-model="form.category"
                :disabled="Boolean(category)"
                :options="extendedCategories"
                track-by="id"
                label="name"
                :multiple="false"
                :allow-empty="false"
                :placeholder="$t('form.category-placeholder')"
                :maxHeight="240"
                :class="{
                  'is-invalid': submitted && $v.form.category.$error,
                }"
              />
              <div
                v-if="submitted && $v.form.category.$error"
                class="invalid-feedback"
              >
                <div v-if="!$v.form.category.required">
                  {{ $t("form.category-req") }}
                </div>
              </div>
            </div>
          </div>

          <!-- subcategory -->
          <div
            v-if="
              form.category &&
                extendedSubCategories &&
                extendedSubCategories.length
            "
            class="form-group mb-2"
          >
            <label for="subcategory" class="required">{{
              $t("form.subcategory")
            }}</label>
            <div v-if="type === 'view'" class="input-group input-group-merge">
              <input
                v-if="form.subcategory && form.subcategory.name"
                :value="form.subcategory.name"
                readonly
                class="form-control form-control-plaintext"
              />
              <input
                v-else
                value=""
                readonly
                class="form-control form-control-plaintext"
              />
            </div>
            <div v-else class="input-group input-group-merge">
              <multiselect
                v-model="form.subcategory"
                :disabled="Boolean(subcategory)"
                :options="extendedSubCategories"
                track-by="id"
                label="name"
                :multiple="false"
                :allow-empty="false"
                :placeholder="$t('form.subcategory-placeholder')"
              />
            </div>
          </div>
        </template>

        <!-- metrics -->
        <div v-for="(m, i) in form.metrics" :key="i" class="form-group mb-2">
          <label :for="`metric-${i}`" class="required"
            >{{ $t("form.metric") }} {{ i + 1 }}</label
          >
          <div class="input-group input-group-merge">
            <input
              class="form-control"
              v-model="form.metrics[i].name"
              :id="`metric-${i}`"
              :ref="`metric-${i}`"
              :placeholder="$t('form.metric-placeholder')"
              :readonly="type === 'view'"
              :class="[
                {
                  'is-invalid': submitted && !form.metrics[i].name,
                },
                type === 'view' ? 'form-control-plaintext' : 'form-control',
              ]"
            />
            <div
              v-if="type !== 'view' && form.metrics.length > 1"
              class="input-group-append"
              @click="removeMetric(i)"
              role="button"
              :title="$t('metric.remove')"
            >
              <div class="input-group-text">
                <span class="mdi mdi-close"></span>
              </div>
            </div>
          </div>
          <div class="input-group input-group-merge">
            <div v-if="type === 'view'">
              {{ form.metrics[i].described }}
            </div>
            <b-form-textarea
              v-else
              v-model="form.metrics[i].described"
              :placeholder="$t('form.metric-descr')"
              rows="3"
              max-rows="6"
              class="mt-1"
            />
          </div>
        </div>

        <div
          v-if="form.metrics.length < maxMetric && type !== 'view'"
          class="mb-2"
        >
          <a href="#" @click.prevent="addMetric"
            ><span class="ri-add-fill"></span> {{ $t("metric.add") }} ({{
              form.metrics.length
            }}
            / {{ maxMetric }})</a
          >
        </div>

        <template v-if="!custom">
          <!-- CREATE button -->
          <div v-if="type === 'create'" class="mt-4 text-center text-lg-right">
            <div class="form-group mb-0">
              <button
                v-if="modal"
                :disabled="inprogress"
                @click.prevent="
                  $root.$emit('bv::hide::modal', 'create-template-modal')
                "
                class="btn btn-warning mx-2 mb-2"
              >
                {{ $t("btn.cancel") }}
              </button>
              <router-link
                v-else
                to="/templates"
                class="btn btn-warning mx-2 mb-2"
              >
                {{ $t("btn.cancel") }}
              </router-link>
              <button
                :disabled="inprogress"
                class="btn btn-primary mx-2 mb-2 mr-lg-0"
                type="submit"
              >
                {{ $t("template.add") }}
              </button>
            </div>
          </div>

          <!-- EDIT button -->
          <div v-if="type === 'edit'" class="mt-4 text-center text-lg-right">
            <div class="form-group mb-0">
              <button
                v-if="modal"
                :disabled="inprogress"
                @click.prevent="
                  $root.$emit('bv::hide::modal', 'create-template-modal')
                "
                class="btn btn-warning mx-2 mb-2"
              >
                {{ $t("btn.cancel") }}
              </button>
              <router-link
                v-else
                to="/templates"
                class="btn btn-warning mx-2 mb-2"
              >
                {{ $t("btn.cancel") }}
              </router-link>
              <button
                :disabled="inprogress"
                class="btn btn-primary mx-2 mb-2 mr-lg-0"
                type="submit"
              >
                {{ $t("btn.save") }}
              </button>
            </div>
          </div>

          <!-- VIEW button -->
          <div v-if="type === 'view'" class="mt-4 text-center text-lg-right">
            <div class="form-group mb-0">
              <button
                v-if="modal"
                :disabled="inprogress"
                @click.prevent="
                  $root.$emit('bv::hide::modal', 'create-template-modal')
                "
                class="btn btn-warning mx-2 mb-2"
              >
                {{ $t("btn.cancel") }}
              </button>
              <router-link
                v-else
                to="/templates/"
                class="btn btn-warning mx-2 mb-2"
              >
                {{ $t("btn.back") }}
              </router-link>
              <router-link
                :to="`/templates/edit/${$route.params.id}`"
                class="btn btn-primary mx-2 mb-2 mr-lg-0"
                >{{ $t("btn.edit") }}</router-link
              >
            </div>
          </div>
        </template>
      </form>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  props: {
    type: {
      type: String, // create, edit, view
      required: true,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Object,
      default: null,
    },
    subcategory: {
      type: Object,
      default: null,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      loading: true,
      submitted: false,
      inprogress: false,
      msg: {
        has: false,
        type: "",
        text: "",
      },
      maxMetric: 5,
      form: {
        name: "",
        type: "", // 0 (subcategory_id) / 1 (user_id)
        templateable_id: "", // subcategory.id / 0 (custom) / category.id
        metrics: [
          {
            name: "",
            type: 0,
            described: "",
            status: 1,
            url: "",
            field: "",
          },
        ],

        category: "",
        subcategory: "",
      },
    };
  },
  validations() {
    return {
      form: this.formRules,
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters(["categories"]),

    extendedCategories() {
      const categories = [...this.categories];
      if (this.custom) {
        categories.push({ id: 0, name: this.$t("template.custom") });
      }

      return categories;
    },

    extendedSubCategories() {
      // if has subcategories return extendedn with NONE, else EMPTY
      if (this.form.category?.subcategories?.length) {
        return [{ id: 0, name: "NONE" }, ...this.form.category.subcategories];
      } else {
        return [];
      }
    },

    formRules() {
      let rules = {};

      rules.name = {
        required,
      };

      rules.category = {
        required,
      };

      return rules;
    },
  },
  methods: {
    async loadData() {
      try {
        await Promise.all([this.$store.dispatch("fetchCategories")]);

        if (this.type === "edit" || this.type === "view") {
          await this.getTemplate();
        } else {
          this.form.category = this.categories[0]; // set default category - first of categories

          if (this.category) {
            this.form.category = this.category;
          }

          if (this.subcategory) {
            this.form.subcategory = this.subcategory;
          }
        }

        this.loading = false;
      } catch (error) {
        console.log("loadData Error: ", error);
      }
    },
    async getTemplate() {
      this.loading = true;

      await axios
        .get(this.$urls.URL_TEMPLATES + "/" + this.$route.params.id)
        .then((response) => {
          console.log(
            `response, template id (${this.$route.params.id}): `,
            response
          );

          this.fillInitData(response);
          this.loading = false;
        })
        .catch((error) => {
          console.log("getTemplate Error", error);
          this.$router.push("/login");
        });
    },
    fillInitData(response) {
      this.form = {
        name: response.data.name,
        type: response.data.type,
        templateable_id: response.data.templateable_id,
        metrics: response.data.metrics,
        category: "",
        subcategory: "",
      };

      if (response.data.type === 0) {
        // subcategory
        const category_id = response.data.templateable.category_id;
        const subcategory_id = response.data.templateable_id;

        this.form.category = this.extendedCategories?.find(
          (c) => String(c.id) === String(category_id)
        );

        this.form.subcategory = this.extendedSubCategories?.find(
          (c) => String(c.id) === String(subcategory_id)
        );

        console.log("subcategory, subcategory_id: ", subcategory_id);
        console.log(
          "subcategory, extendedSubCategories: ",
          this.extendedSubCategories
        );
      } else if (response.data.type === 1) {
        // custom
        this.form.category = this.extendedCategories[
          this.extendedCategories.length - 1
        ];
      } else if (response.data.type === 2) {
        // category
        const category_id = response.data.templateable_id;

        this.form.category = this.extendedCategories?.find(
          (c) => String(c.id) === String(category_id)
        );
      }
    },
    async handleSubmit() {
      this.submitted = true;
      this.msg = {
        has: false,
        type: "",
        text: "",
      };

      this.$v.$touch();

      // empty metrics
      if (!this.form.metrics.length) {
        this.msg.has = true;
        this.msg.type = "danger";
        this.msg.text = this.$t("metric.one-at-least");

        return false;
      }

      // validate each metric on empty value
      const hasEmptyMetrics =
        this.form.metrics.filter((m) => !m.name).length !== 0;
      if (hasEmptyMetrics) {
        return false;
      }

      if (!this.$v.form.$invalid) {
        this.inprogress = true;

        const formData = new FormData();

        // form
        formData.append("name", this.form.name);
        formData.append("type", this.form.type);
        formData.append("templateable_id", this.form.templateable_id);
        this.form.metrics.map((m) => {
          formData.append("metrics[]", JSON.stringify(m));
        });

        // Display the key/value pairs ###debug
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + " :", pair[1]);
        // }

        if (this.type === "create") {
          try {
            const response = await axios.post(
              this.$urls.URL_TEMPLATES,
              formData,
              {
                headers: {
                  "content-type": "multipart/form-data",
                },
              }
            );
            // console.log("response: ", response);

            this.msg.has = true;
            this.msg.type = "success";
            this.msg.text = this.$t("template.msg-add-success");

            if (this.modal) {
              this.$emit("update", response.data.template);
              this.$root.$emit("bv::hide::modal", "create-template-modal");
            }

            this.inprogress = false;
            return response;
          } catch (error) {
            console.log("Error: ", error);
            this.msg.has = true;
            this.msg.type = "danger";
            this.msg.text =
              error.response?.data?.message ||
              this.$t("template.msg-add-error");

            this.inprogress = false;

            if (error.response.data.errors) {
              for (const [key, value] of Object.entries(
                error.response.data.errors
              )) {
                this.msg.text += `[${key}: ${value}] `;
              }
            }

            return false;
          } finally {
            this.$scrollToTop();
          }
        }

        if (this.type === "edit") {
          formData.append("_method", "PATCH");

          try {
            const response = await axios.post(
              this.$urls.URL_TEMPLATES + "/" + this.$route.params.id,
              formData,
              {
                headers: {
                  "content-type": "multipart/form-data",
                },
              }
            );
            // console.log("response: ", response);

            this.msg.has = true;
            this.msg.type = "success";
            this.msg.text = this.$t("template.msg-edit-success");

            this.inprogress = false;
          } catch (error) {
            console.log("Error: ", error);
            this.msg.has = true;
            this.msg.type = "danger";
            this.msg.text =
              error.response?.data?.message ||
              this.$t("template.msg-edit-error");

            this.inprogress = false;

            if (error.response.data.errors) {
              for (const [key, value] of Object.entries(
                error.response.data.errors
              )) {
                this.msg.text += `[${key}: ${value}] `;
              }
            }
          } finally {
            this.$scrollToTop();
          }
        }
      }
    },
    removeMetric(i) {
      this.form.metrics.splice(i, 1);
    },
    addMetric() {
      this.form.metrics.push({
        name: "",
        type: 0,
        described: "",
        status: 1,
        url: "",
        field: "",
      });

      setTimeout(() => {
        this.$refs[`metric-${this.form.metrics.length - 1}`][0].focus();
      });
    },
    setCategory() {
      if (this.form.category?.id) {
        // category
        this.form.type = 2;
        this.form.templateable_id = this.form.category.id;
      } else {
        // custom
        this.form.type = 1;
        this.form.templateable_id = 0;
      }
    },
  },
  watch: {
    "form.category"() {
      this.setCategory();
    },
    "form.subcategory"() {
      // console.log("watcher form.subcategory");
      if (this.form.subcategory?.id) {
        // subcategory
        this.form.type = 0;
        this.form.templateable_id = this.form.subcategory.id;
      } else {
        // if NONE subcategory selected (id === 0)
        this.setCategory();
      }
    },
    "form.category.subcategories"() {
      this.form.subcategory = this.extendedSubCategories?.find(
        (c) => String(c.id) === String(this.form.subcategory?.id)
      );

      // if not find, set first - NONE
      if (!this.form.subcategory) {
        this.form.subcategory = this.extendedSubCategories[0];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  min-width: 180px;
}

.form-control-plaintext {
  background: transparent;
  border: none;
  color: #000;
}
</style>
